import axios from '@/shared/axios';
// import {
//   HairCollectListModel,
//   PlanbowlResultItemModel,
//   HairLibPublicModel,
//   HairRecordsBalanceListModel,
//   HairShareInfoModel,
//   HairShareListModel,
//   PlanbowlAddInfoModel,
// } from '@/types/planbowl';
import {
  HomeArticleModel,
  OrderModel,
  PlanbowlArticleListModel,
  PlanbowlArtileSideInfoModel,
} from '@/types/home';
import {
  HairCollectListModel,
  HairLibPublicModel,
  HairRecordsBalanceListModel,
  HairShareInfoModel,
  HairShareListModel,
  PlanbowlAddInfoModel,
  PlanbowlReportDetailModel,
  PlanbowlResultItemModel,
} from '@/types/planbowl';
import { BaseResponse } from '@/types/web.interface';

export async function addInfoRequest(params?: any, locale?: string) {
  const res = await axios.post<BaseResponse<PlanbowlAddInfoModel>>(
    `/planbowl/add_info`,
    {
      ...params,
    },
    {
      headers: {
        'Current-Language': locale,
      },
    },

    // {
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    // },
  );
  return res.data;
}

// 购买
export async function orderSubsRequest(
  identification?: any,
  item_id?: number,
  redirectUrl?: string | string[],
) {
  const res = await axios.post<BaseResponse<OrderModel>>(
    `/planbowl/buy`,
    {
      identification,
      redirect_url: redirectUrl,
      item_id,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// report list
export async function fetchPlanbowlReportsData(locale?: string) {
  const res = await axios.get<BaseResponse<[PlanbowlResultItemModel]>>(`/planbowl/reports`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// report title
export async function planbowlReportTitleRequest(identification?: any, title?: string) {
  const res = await axios.post<BaseResponse<any>>(
    `/planbowl/report/title`,
    {
      identification,
      title,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// report change
export async function planbowlReportChangeRequest(
  identification?: any,
  material_id?: any,
  content?: any,
  unit?: any,
) {
  const res = await axios.post<BaseResponse<any>>(
    `/planbowl/report/change`,
    {
      identification,
      material_id,
      content,
      unit,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return res.data;
}

export async function planbowlReportDetailRequest(identification?: any) {
  const res = await axios.get<BaseResponse<PlanbowlReportDetailModel>>(`/planbowl/report/detail`, {
    params: {
      identification,
    },
  });
  return res.data;
}

export async function planbowlReportPageRequest(identification?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[string]>>(`/planbowl/report/page/${identification}`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function planbowlFeedbackRequest(point?: any, feedback?: any, feature?: any) {
  const res = await axios.post<BaseResponse<any>>(`/planbowl/feedback`, {
    point,
    feedback,
    feature,
  });
  return res.data;
}

// 点数记录
export async function fetchHairstyleRecordsBalanceListData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairRecordsBalanceListModel]>>(
    `/hairstyle/item/balance/records`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 文章数据
export async function fetchHairstyleArticleData(keyword?: string, locale?: string) {
  const res = await axios.get<BaseResponse<any>>(`/hairstyle/article/data/${keyword}`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 创作列表
export async function fetchHairstyleCreateListData(locale?: string) {
  const res = await axios.get<BaseResponse<[PlanbowlResultItemModel]>>(
    `/hairstyle/mine/list/create`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 查看分享内容
export async function fetchHairstyleMineShareData(linkId?: any, locale?: string) {
  const res = await axios.get<BaseResponse<HairShareInfoModel>>(`/hairstyle/mine/share/${linkId}`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 单张删除
export async function fetchHairstyleCreateDeleteData(createId?: any, locale?: string) {
  const res = await axios.post<BaseResponse<any>>(`/hairstyle/create/delete/${createId}`, {
    headers: {
      'Current-Language': locale,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data;
}

// cancel
export async function fetchHairstyleOrderCancelData(reasonType?: any, locale?: string) {
  const res = await axios.post<BaseResponse<any>>(
    `/hairstyle/item/subs/cancel`,
    {
      reasonType,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// resume
export async function fetchHairstyleOrderResumeData(locale?: string) {
  const res = await axios.post<BaseResponse<any>>(`/hairstyle/item/subs/resume`, {
    headers: {
      'Current-Language': locale,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data;
}

// 创作结果查询，多id
export async function fetchHairstyleCreateResultsData(create_ids: any, locale?: string) {
  const res = await axios.get<BaseResponse<[PlanbowlResultItemModel]>>(
    `/hairstyle/create/results`,
    {
      params: {
        create_ids: `${create_ids}`,
      },
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 分享点赞、点踩
export async function fetchHairstyleMineShareOperateData(
  linkId?: any,
  share_image_id?: any,
  operate?: 'like' | 'dislike',
  locale?: string,
) {
  const res = await axios.post<BaseResponse<any>>(
    `/hairstyle/mine/share/operate/${linkId}`,
    {
      operate,
      share_image_id,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// 分享列表
export async function fetchHairstyleMineListShareData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairShareListModel]>>(`/hairstyle/mine/list/share`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 收藏列表
export async function fetchHairstyleMineListCollectData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairCollectListModel]>>(
    `/hairstyle/mine/list/collect`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 收藏操作
export async function fetchHairstyleMineCollectOperateData(
  hairstyle_id?: any,
  operate?: 'add' | 'remove',
  locale?: string,
) {
  const res = await axios.post<BaseResponse<any>>(
    `/hairstyle/mine/collect/${operate}`,
    {
      hairstyle_id,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// 移除未读消息
export async function fetchHairstyleRemoveUnreadData(shareId?: any, locale?: string) {
  const res = await axios.post<BaseResponse<any>>(`/hairstyle/mine/share/unread/${shareId}`, {
    headers: {
      'Current-Language': locale,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data;
}

// 分享创建
export async function fetchHairstyleCreateShareData(params?: any, locale?: string) {
  const res = await axios.post<BaseResponse<HairShareInfoModel>>(
    `/hairstyle/mine/share/create`,
    {
      ...params,
    },
    {
      headers: {
        'Current-Language': locale,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// 随机发型数据
export async function fetchHairstyleLibRandomData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairLibPublicModel]>>(`/hairstyle/lib/random`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 上一次创作的图片
export async function fetchHairstyleLastImageData(locale?: string) {
  const res = await axios.get<BaseResponse<string>>(`/hairstyle/user/last_image`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 示例报告列表
export async function fetchDemoReportListData(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<PlanbowlResultItemModel[]>>(`/planbowl/demo/reports`, {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getPlanbowlArticleListData(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>('/planbowl/article/article', {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getPlanbowlArticleDisasterData(locale?: string) {
  const res = await axios.get('/planbowl/article/disaster/data', {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getPlanbowlRecommendSideListData(
  type?: any,
  currentId?: any,
  locale?: string,
) {
  const res = await axios.get<BaseResponse<[PlanbowlArtileSideInfoModel]>>(
    `/article/list/recommend/${type}/${currentId}`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 场景列表
export async function getPlanbowlSceneListData(locale?: string) {
  const res = await axios.get<BaseResponse<[PlanbowlArticleListModel]>>(`/planbowl/scene/list`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 反馈心意场景
export async function feedbackScene(feedback?: string, locale?: string) {
  const res = await axios.post<BaseResponse<any>>(`/planbowl/scene/${feedback}`);
  return res.data;
}
