import React, { createContext, useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import {
  getAuth,
  signOut,
  deleteUser,
  getRedirectResult,
  GoogleAuthProvider,
  // signInWithCredential,
} from 'firebase/auth';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import { userUpdate } from '@/lib/service';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import { PlanbowlArticleListModel } from '@/types/home';
import { ReportType } from '@/components/Common/CreateReportAlertContent';
// import { debounce } from 'lodash-es';

type UserProviderType = {
  /**
   * total cart quantity
   */
  // total?: number;
  openLogin?: boolean;
  isLoginView?: boolean;
  openLoginResult?: boolean;
  // accountList?: InsMonitorModel[];
  /**
   * add cart callback function
   * @param params Product information added
   */
  onAddShop?: <P = any>(params: P) => Promise<{ data: P }>;
  onDeleteShopCarItem?: <P = any>(params: P) => Promise<{ data: P }>;
  onOpenLoginModal?: (open: boolean) => void;
  onChangedLoginView?: (isLoginView: boolean) => void;
  onChangedLoginResult?: (isLoginView: boolean) => void;
  onAddToken?: (token?: string, signInMethod?: string, idToken?: string) => void;
  // onInitAccountList?: (accountList?: InsMonitorModel[]) => void;
  // onAddAccount?: (account?: InsMonitorModel) => void;
  onSignOut?: (showAlert?: boolean) => void;
  onDeleteUser?: () => void;

  /**
   * Refresh cart data
   */
  onRefreshCartData?: () => Promise<void>;

  isLogin?: boolean;
  getLoginState?: (isLogin?: boolean) => void;

  // share count
  onShareUnreadCount?: (shareCount?: number) => void;
  shareUnreadCount?: number;

  // credits count
  onCreditsChanged?: (count?: number) => void;
  creditsCount?: number;

  // open
  isOpenCreateReportAlert?: boolean;
  onOpenCreateReportAlert?: (open?: boolean) => void;

  // get started
  isGetStartedClick?: 'none' | 'Single' | 'Complex' | string;
  onGetStartedClick?: (type?: 'none' | 'Single' | 'Complex') => void;

  // need reload data
  isNeedRefreshReportData?: boolean;
  onNeedRefreshReportData?: (refresh?: boolean) => void;

  isOpenSelectReportsAlert?: boolean;
  onOpenSelectReportsAlert?: (open?: boolean) => void;

  currentReportType?: ReportType;
  onCurrentReportType?: (report?: ReportType) => void;

  currentReport?: PlanbowlArticleListModel;
  onCurrentReport?: (report?: PlanbowlArticleListModel) => void;

  // sceneListData?: PlanbowlArticleListModel[];
};

const Context = createContext<UserProviderType>({});

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  // const { message } = App.useApp();

  const router = useRouter();
  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [isLoginView, setIsLoginView] = useState<boolean>(true);
  const [openLoginResult, setOpenLoginResult] = useState<boolean>(false);
  const [accountList, setAccountList] = useState([]);
  const [shareUnreadCount, setShareUnreadCount] = useState<number>(0);
  const [creditsCount, setCreditsCount] = useState<number>(0);
  const [isOpenCreateReportAlert, setOpenCreateReportAlert] = useState(false);
  const [isNeedRefreshReportData, setIsNeedRefreshReportData] = useState(false);
  const [isGetStartedClick, setIsGetStartedClick] = useState('none');
  const [isOpenSelectReportsAlert, setOpenSelectReportsAlert] = useState(false);
  const [currentReportType, setCurrentReportType] = useState<ReportType>(ReportType.COMPLEX);
  const [currentReport, setCurrentReport] = useState<PlanbowlArticleListModel>();
  // const [sceneListData, setSceneListData] = useState<PlanbowlArticleListModel[]>([]);

  // const once = useRef(true);

  const token = Cookies.get('userToken');
  const [isLogin, setIsLogin] = useState<boolean>(
    token != null && token != undefined && token.length > 0,
  );

  // const token1 = ls_getItem('userToken');
  // const [isLogin, setIsLogin] = useState<boolean>((token != null && token != undefined && token.length > 0) || (token1 != null && token1 != undefined && token1.length > 0));

  const handleAddShopCarItem = async (params: any) => {
    return Promise.resolve(params);
  };

  const onNeedRefreshReportData = (refresh?: boolean) => {
    setIsNeedRefreshReportData(refresh);
  };

  useEffect(() => {
    // The initialization is to get the total number of shopping carts
    const token = Cookies.get('userToken');
    setIsLogin(token != null && token != undefined && token.length > 0);

    // const toen1 = ls_getItem('userToken');
    // setIsLogin((token != null && token != undefined && token.length > 0) || (token1 != null && token1 != undefined && token1.length > 0));
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      //   setTimeout(() => {
      loadUserData();
      //   }, 50);
    }
    // if (once.current) {
    //   debounceScene();
    // }
  }, []);
  // const getlSceneListData = useCallback(() => {
  //   getPlanbowlSceneListData(router?.locale)
  //     .then((res) => {
  //       setSceneListData([...(res?.data ?? [])]);
  //     })
  //     .catch((e) => {
  //       message.error(e.message);
  //     })
  //     .finally(() => {
  //       once.current = false;
  //     });
  // }, []);

  // const debounceScene = debounce(getlSceneListData, 500);

  const getToken = (id: string, signInMethod: string) => {
    const pathUrl =
      typeof window !== undefined ? window.location.href : process.env.NEXT_APP_WEB_HOST;
    // loginRedirect(id, pathUrl, router.locale).then((res) => {
    //   if (res?.code == 0) {
    //     // onAddToken(res?.data?.token, signInMethod, id);
    //     // // onOpenLoginModal(false);
    //     // window.location.reload()
    //   } else {
    //     message.error(res.message);
    //   }
    // });

    userUpdate(id, router.locale).then((res) => {
      if (res?.data?.first_login) {
        if (res.code == 0) {
          ga_logEvent(EventName.user_register_Success);
        } else {
          ga_logEvent(EventName.user_register_Fail);
        }
      }

      if (res?.code == 0) {
        // window.location.reload()
        // onAddToken(res?.data?.token, signInMethod, id);
        // onOpenLoginModal(false);
        router.replace(`/login-success?token=${res?.data?.token}&url=${pathUrl}`);
        ga_logEvent(EventName.user_Login_Success);
      } else {
        message.error(res.message);
      }
    });
  };

  const loadUserData = () => {
    const auth = getAuth();
    getRedirectResult(auth).then(async (result) => {
      if (result) {
        const credential = GoogleAuthProvider.credentialFromResult(result);

        const token = credential.accessToken;
        Cookies.set('accessToken', token, { expires: 7 });
        // ls_saveItem('accessToken', token)

        result.user.getIdToken().then((id) => {
          getToken(id, 'google');
          ga_logEvent(EventName.login, { method: 'Google' });
        });
      }
    });
  };

  const onOpenLoginModal = (open: boolean) => {
    setOpenLogin(open);
  };

  const onChangedLoginView = (open: boolean) => {
    setIsLoginView(open);
  };

  const onChangedLoginResult = (open: boolean) => {
    setOpenLoginResult(open);
  };

  const handleRefreshCartData = async () => {
    return new Promise<void>((resolve) => {
      message.success('Refresh successfully', 1.2, () => resolve(null));
    });
  };

  const onAddToken = (token?: string, signInMethod?: string, idToken?: string) => {
    setIsLogin(token != undefined);

    if (token != undefined) {
      Cookies.set('userToken', token, { expires: 7 });
      Cookies.set('SignInMethod', signInMethod, { expires: 7 });
      Cookies.set('IdToken', idToken, { expires: 7 });
      // ls_saveItem('userToken', token);
      // ls_saveItem('SignInMethod', signInMethod);
      // ls_saveItem('IdToken', idToken);
    } else {
      Cookies.remove('userToken');
      Cookies.remove('SignInMethod');
      Cookies.remove('IdToken');

      // ls_removeItem('userToken');
      // ls_removeItem('SignInMethod');
      // ls_removeItem('IdToken');
    }
  };

  const onSignOut = (showAlert?: boolean) => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        if (showAlert) {
          message.success(t('SignOutSuccessful'));
          onAddToken(undefined);
          setShareUnreadCount(0);
        }
        onAddToken(undefined);
      })
      .catch((error) => {
        message.error(t('Log_Out_Failed_Error_Msg'));
      });
  };

  const onDeleteUser = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    deleteUser(user)
      .then(() => {
        message.success(t('UserDeleted'));
        onAddToken(undefined);
        setAccountList([]);
        setShareUnreadCount(0);

        router.replace('/');
      })
      .catch((error) => {
        message.error(t('Please log in again before deleting your account.'));
        onChangedLoginView(true);
        onOpenLoginModal(true);

        // message.error(t('Account_Deletion_Failed_Error_Msg'));
      });
  };

  const onShareUnreadCount = (shareCount?: number) => {
    setShareUnreadCount(shareCount);
  };

  const onCreditsChanged = (count?: number) => {
    setCreditsCount(count >= 0 ? count : 0);
  };

  const onOpenCreateReportAlert = (open?: boolean) => {
    setOpenCreateReportAlert(() => open);
  };

  const onGetStartedClick = (type?: string) => {
    setIsGetStartedClick(type);
  };

  const onOpenSelectReportsAlert = (open?: boolean) => {
    setOpenSelectReportsAlert(() => open);
  };

  const onCurrentReportType = (report: ReportType) => {
    setCurrentReportType(() => report);
  };

  const onCurrentReport = (report: PlanbowlArticleListModel) => {
    setCurrentReport({ ...report });
  };

  const exposed = {
    openLogin,
    isLoginView,
    openLoginResult,
    // total: totalCartQuantity,
    isLogin,
    accountList,
    onAddShop: handleAddShopCarItem,
    onOpenLoginModal,
    onChangedLoginView,
    onChangedLoginResult,
    onRefreshCartData: handleRefreshCartData,
    onAddToken,
    onSignOut,
    onDeleteUser,
    onShareUnreadCount,
    shareUnreadCount,
    onCreditsChanged,
    creditsCount,
    isOpenCreateReportAlert,
    onOpenCreateReportAlert,
    isOpenSelectReportsAlert,
    onOpenSelectReportsAlert,
    isNeedRefreshReportData,
    onNeedRefreshReportData,
    isGetStartedClick,
    onGetStartedClick,
    currentReportType,
    onCurrentReportType,
    currentReport,
    onCurrentReport,
    // sceneListData,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useUserProvider = () => useContext(Context);

export default UserProvider;
