import axios from '@/shared/axios';
import {
  BabyNameDetailModel,
  BabyNameModel,
  BabyPhotoModel,
  OrderModel,
  PaymentHistoryModel,
} from '@/types/home';
import { BaseResponse } from '@/types/web.interface';

export async function getBabyNameList(locale?: string, params?: any) {
  const res = await axios.get<BaseResponse<[BabyNameModel]>>(`/moms/babyname/page`, {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getBabyNameDetailData(locale?: string, params?: any) {
  const res = await axios.get<BaseResponse<BabyNameDetailModel>>(`/moms/babyname/detail`, {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function generatBabyNameData(locale?: string, params?: any) {
  const res = await axios.post<BaseResponse<[BabyNameModel]>>(
    `/moms/babyname/result`,
    {
      ...params,
    },
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

export async function fetchBabyNameListSourceData(locale?: string) {
  const res = await axios.get<BaseResponse<[string]>>(`/moms/babyname/list/source`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function fetchBabyNameListSimilarData(locale?: string, nameId?: any) {
  const res = await axios.get<BaseResponse<[BabyNameModel]>>(`/moms/babyname/similar/${nameId}`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// photo
export async function fetchBabyPhotoItemsData(locale?: string) {
  const res = await axios.get<BaseResponse<BabyPhotoModel>>(`/moms/baby_photo/items`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function babyPhotoBuy(locale?: string, params?: any) {
  const res = await axios.post<BaseResponse<OrderModel>>(
    `/moms/baby_photo/buy`,
    {
      ...params,
    },
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

export async function fetchBabyPhotoHistoryData(locale?: string, params?: any) {
  const res = await axios.get<BaseResponse<[PaymentHistoryModel]>>(`/moms/baby_photo/history`, {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function fetchBabyPhotoHistoryDetailData(locale?: string, baby_photo_id?: any) {
  const res = await axios.get<BaseResponse<PaymentHistoryModel>>(
    `/moms/baby_photo/history/detail`,
    {
      params: {
        baby_photo_id,
      },
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

export async function babyPhotoDownloadData(locale?: string, params?: any) {
  const image_ids = params?.image_ids ?? [];
  // 'Content-Type': 'image/jpeg' : 'application/zip'

  const headers =
    image_ids?.length === 0
      ? {
          'Current-Language': locale,
          'Content-Type': 'image/jpeg',
        }
      : {
          'Current-Language': locale,
        };

  const res = await axios.post<any>(
    `/moms/baby_photo/batch/download`,
    {
      ...params,
    },
    {
      responseType: 'blob',
      headers: {
        ...headers,
        // 'Current-Language': locale,
        // 'Content-Type': (image_ids?.length === 0) ? 'image/jpeg' : 'application/zip'
      },
    },
  );
  return res.data;
}

// // update image
// export const updateImageData = async (file: File) => {
//   if (file === null) {
//     return {
//       code: 0,
//       message: '',
//       data: null
//     };
//   }

//   const formData = new FormData();
//   formData.append('image', file);

//   try {
//     const res = await axios.post<BaseResponse<string>>(`/drawing/image/upload`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });

//     return res.data;
//   } catch (error) {
//     return {
//       code: -1,
//       message: 'error',
//     };
//   }
// };
