import { Button } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import style from './index.module.less';
import { CheckOutlined } from '@ant-design/icons';
import { ReportType } from '../CreateReportAlertContent';

type PatData = {
  [key: string]: { title: string; currentPrice: string; originalPrice: string; rights: string[] };
};

enum PayIndex {
  COMBINATION = 1,
  COMPLEX = 2,
  SINGLE_SCENE = 3,
}

const PayAlertContent = ({
  report_type,
  onConfirm,
}: {
  report_type: ReportType;
  onConfirm: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const [reportType, setReportType] = useState<ReportType>(report_type);
  const currentType = useRef(report_type);

  const payData: PatData = {
    COMPLEX: {
      title: t('All-In-One Emergency Prep'),
      currentPrice: '$29.99',
      originalPrice: '$59.9',
      rights: [
        t('Covers 12 emergencies'),
        t('Personalized supply list'),
        t('Government, NGO resources'),
        t('Downloadable'),
        t('Editable'),
      ],
    },
    SINGLE_SCENE: {
      title: t('Single-Event Emergency Prep'),
      currentPrice: '$19.99',
      originalPrice: '$39.9',
      rights: [
        t('For specific emergencies'),
        t('Personalized supply list'),
        t('Disaster-specific guide'),
        t('Downloadable'),
        t('Includes pet supplies'),
      ],
    },
    COMBINATION: {
      title: t('Planbowl Premium Prep Package'),
      currentPrice: '$59.9',
      originalPrice: '$169',
      rights: [
        `${t('Get')} <span style="color:#0D6E4B">${t('1 All-In-One')}</span> ${t(
          'Emergency Prep',
        )}`,
        `${t('Plus')} <span style="color:#0D6E4B">${t('7 Single-Event')}</span> ${t(
          'Emergency Preps',
        )}`,
        `${t('Includes all benefits from both the')} <span style="color:#0D6E4B">${t(
          'All-In-One and Single-Event',
        )}</span> ${t('Emergency Prep plans')}`,
      ],
    },
  };

  return (
    <div className={style.content}>
      <div className={style.tabs}>
        <div className={style.tabsTip}>{t('Save')} 65%</div>
        <div
          onClick={() => {
            setReportType(currentType.current);
          }}
          className={style.tab}
          style={{ color: reportType === currentType.current && '#fff' }}
        >
          <span>{t(currentType.current)}</span>
        </div>
        <div
          onClick={() => {
            setReportType(ReportType.COMBINATION);
          }}
          className={style.tab}
          style={{ color: reportType === ReportType.COMBINATION && '#fff' }}
        >
          <span>{t(ReportType.COMBINATION)}</span>
        </div>
        <div
          className={style.activeTab}
          style={{ transform: `translateX(${reportType !== ReportType.COMBINATION ? 0 : 100}%)` }}
        />
      </div>
      <div className={style.title}>
        <h3>{payData[reportType].title}</h3>
        <div className={style.price}>
          <div className={style.currentPrice}>{payData[reportType].currentPrice}</div>
          <span className={style.originalPrice}>{payData[reportType].originalPrice}</span>
        </div>
      </div>
      <div className={style.rights}>
        {reportType !== ReportType.COMBINATION &&
          payData[reportType].rights?.map((item, index) => (
            <div key={index} className={style.rightsItem}>
              <CheckOutlined className={style.rightsIcon} />
              <div className={style.rightsText}>{item}</div>
            </div>
          ))}
        {reportType === ReportType.COMBINATION &&
          payData[reportType].rights?.map((item, index) => (
            <div key={index} className={style.rightsItem}>
              <CheckOutlined className={style.rightsIcon} />
              <div className={style.rightsText} dangerouslySetInnerHTML={{ __html: item }}></div>
            </div>
          ))}
      </div>

      <Button
        onClick={() => onConfirm(PayIndex[reportType])}
        type="primary"
        className={style.payBtn}
      >
        {t('Invest in Safety Now')}
      </Button>
    </div>
  );
};

export default PayAlertContent;
