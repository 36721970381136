// 例如 next-i18next.config.js
const path = require('path');

module.exports = {
  i18n: {
    defaultLocale: process.env.NEXT_APP_DEFAULT_LOCALE || 'en',
    locales: ['en', 'ja', 'de', 'zh_TW'],
    // locales: ['pt', 'en', 'es', 'ko', 'ja', 'fr', 'de'],
    // defaultNS: 'common',
  },
  localesPath: path.resolve('./public/locales'),
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  fallbackLng: process.env.NEXT_APP_DEFAULT_LOCALE || 'en',
};
