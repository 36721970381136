// import { useTranslation } from "next-i18next"
export const isProd =
  process.env.NODE_ENV === 'production' && !process.env.NEXT_APP_API_HOST.includes('-test.');

// App 1:DRfans, 2:Quotesecho
const AppType = process.env.NEXT_APP_TENANTID;

export const isDrFans = false;

// name
const getAppName = () => {
  return 'Planbowl';
};

export const lowerName = getAppName().toLowerCase();

// app logo
const getAppLogo = `/images/layouts/${lowerName}-logo.png`;
// app net logo
const getAppNetLogo = `https://res-gpts.pumpsoul.com/logo/planbowl-logo.png`;
// app name
const getAppLogoName = `/images/layouts/${lowerName}-name.png`;
// app ico
const getAppICO = `/${lowerName}_favicon.ico`;
// app title
const getAppTitle = () => {
  return '';
};

// app desc
const getAppDesc = () => {
  return ``;
};

// app sub title
const getAppHeadSubTitle = () => {
  return '';
};

export const currentHost = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  if (window.location.host.includes('-test.')) {
    return `www-test.planbowl.com`;
  } else {
    return `www.planbowl.com`;
  }
};

//
const getFirebaseConfig = () => {
  return {
    apiKey: 'AIzaSyCtYAi6uQ89ChSLQ2wa8QaT_xVQKeR8-Vw',
    // authDomain: "planbowl.firebaseapp.com",
    authDomain: `${currentHost()}/fb-auth`,
    projectId: 'planbowl',
    storageBucket: 'planbowl.appspot.com',
    messagingSenderId: '270720791385',
    appId: '1:270720791385:web:e7b71a9c8956323cb79248',
    measurementId: 'G-BGZ507927H',
  };
};

// link
const getClarityId = () => {
  return 'mq6zag0851';
};

interface AppSettings {
  type: number;
  name: string;
  logoUrl: string;
  logoPath: string;
  namePath: string;
  icoPath: string;
  title: string;
  headSubTitle: string;
  desc: string;
  firebaseConfig: {};
  navItems: [];
}

export const AppSettings = {
  type: AppType,
  name: getAppName(),
  logoUrl: getAppNetLogo,
  logoPath: getAppLogo,
  namePath: getAppLogoName,
  icoPath: getAppICO,
  title: getAppTitle(),
  headSubTitle: getAppHeadSubTitle(),
  desc: getAppDesc(),
  firebaseConfig: getFirebaseConfig(),
  clarityId: isProd ? getClarityId() : 'l9q3tnv9s4',
};
