import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { App, Input, Modal, Radio, Select } from 'antd';
import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useUserProvider } from '@/context/UserProvider';
import classNames from 'classnames';
import { addInfoRequest, orderSubsRequest } from '@/lib/service';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import Cookies from 'js-cookie';
import { debounce } from 'lodash';
import PayAlertContent from '../PayAlertContent';

export enum ReportType {
  COMBINATION = 'COMBINATION',
  COMPLEX = 'COMPLEX',
  SINGLE_SCENE = 'SINGLE_SCENE',
}

const { TextArea } = Input;

const DetailContent = () => {
  const inputRef = useRef<any>(null);
  const [isFocused, setIsFocused] = useState(false);

  const { t } = useTranslation();
  const router = useRouter();
  const { message } = App.useApp();

  const stripe = useStripe();
  const elements = useElements();

  const { isOpenCreateReportAlert } = useUserProvider();
  const {
    onOpenLoginModal,
    onOpenCreateReportAlert,
    onGetStartedClick,
    currentReportType,
    currentReport,
    onNeedRefreshReportData,
  } = useUserProvider();
  // 是否是单场景
  const isSingle = currentReportType == ReportType.SINGLE_SCENE;

  const [currentStep, setCurrentStep] = useState(1);
  const [addressParams, setAddressParams] = useState<any>({});
  const [familyInfoParams, setFamilyInfoParams] = useState<any>({});
  const [level, setLevel] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [addressList, setAddressList] = useState<any[]>([]);
  const [payId, setPayId] = useState<any>(null);

  const [addressInputValue, setAddressInputValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const [inputPet, setInputPet] = useState('');
  const [havePet, setHavePet] = useState(1);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((value) => {
        setDebouncedValue(value);
      }, 500),
    [],
  );

  const handleChange = (value: string) => {
    setInputValue(value);
    debouncedChangeHandler(value);
  };

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  useEffect(() => {
    if (debouncedValue) {
      onAddressSearch(debouncedValue);
    }
  }, [debouncedValue]);

  const getLoginState = () => {
    const token = Cookies.get('userToken');
    return token != null && token != undefined && token.length > 0;
  };

  const orderSubscribeRequest = async (identification: any, index: number) => {
    if (!stripe || !elements) {
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await orderSubsRequest(identification, index, '/dashboard');
    if (res?.code === 0) {
      if (res?.data?.order_id != undefined && res?.data?.client_secret != undefined) {
        loadScripePay(res?.data?.client_secret);
      }
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const loadScripePay = async (client_secret: any) => {
    const _ = await stripe?.redirectToCheckout({
      sessionId: client_secret,
    });
  };

  const addInfo = () => {
    if (loading) {
      return;
    }
    setLoading(true);

    const tempParams = {
      ...(addressParams ?? {}),
      family_info: { ...(familyInfoParams ?? {}) },
      floor_level: level,
      report_type: currentReportType,
    };

    if (tempParams.report_type === ReportType.SINGLE_SCENE) {
      tempParams.report_scene = currentReport.report_scene;
      tempParams.pet_info = inputPet;
    }

    addInfoRequest(tempParams, router?.locale)
      .then((res) => {
        if (res?.code === 0) {
          if (res?.data?.need_pay) {
            setPayId(res?.data?.identification);
          } else {
            onNeedRefreshReportData(true);
            onOpenCreateReportAlert(false);
            router.push(`/dashboard`);
          }
        } else {
          message.error(res?.message);
        }
      })
      .catch((e) => {
        message.error(e?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProgressState = (steps: number[]) => {
    return steps.map((step) => {
      switch (step) {
        case 1:
          return 1 <= currentStep && addressParams?.address !== undefined;
        case 2:
          return (
            2 <= currentStep &&
            (familyInfoParams?.adult_num !== undefined ||
              familyInfoParams?.children_num !== undefined ||
              familyInfoParams?.elderly_num !== undefined)
          );
        case 3:
          return 3 <= currentStep && level !== undefined;
        case 4:
          return !!(4 <= currentStep && (!havePet || (havePet && inputPet)));
      }
    });
  };

  const getTitleData = useCallback(() => {
    if (currentStep === 1) {
      return {
        title: `1. ${t('Your Location')}`,
        desc: t('Your location helps us analyze high-risk emergencies in your local area.'),
      };
    } else if (currentStep === 2) {
      return {
        title: `2. ${t('Your Family Composition')}`,
        desc: t(
          `Understanding your family's composition allows us to calculate the exact amount and type of supplies you'll need. It ensures everyone in your household is adequately prepared.`,
        ),
      };
    } else if (currentStep === 3) {
      return {
        title: `3. ${t('Your Housing Type')}`,
        desc: t(
          'Knowing your housing type helps us provide specific strategies for dealing with disasters like fires or earthquakes, ensuring your safety.',
        ),
      };
    } else {
      return {
        title: `4. ${t('Do You Have Pets?')}`,
        desc: t(
          'Your pets are family, and their safety matters in an emergency. Single-Threat Readiness includes a detailed supply list for pets, ensuring you have everything needed to protect them.',
        ),
      };
    }
  }, [currentStep]);

  const yourFamilyOptions = [
    {
      value: '0',
      label: '0',
    },
    {
      value: '1',
      label: '1',
    },
    {
      value: '2',
      label: '2',
    },
    {
      value: '3',
      label: '3',
    },
    {
      value: '4',
      label: '4',
    },
    {
      value: '5',
      label: '5',
    },
    {
      value: '6',
      label: '6',
    },
    {
      value: '7',
      label: '7',
    },
    {
      value: '8',
      label: '8',
    },
    {
      value: '9',
      label: '9',
    },
    {
      value: '10',
      label: '10',
    },
    {
      value: '10+',
      label: 'Above 10',
    },
  ];

  const yourHousingTypeOptions = [
    {
      value: t('Detached house'),
      label: t('Detached house'),
    },
    {
      value: t('Multi-family home (duplex, triplex, etc.)'),
      label: t('Multi-family home (duplex, triplex, etc.)'),
    },
    {
      value: t('Apartment in a low-rise building (1-4 floors)'),
      label: t('Apartment in a low-rise building (1-4 floors)'),
    },
    {
      value: t('Apartment in a high-rise building (5 or more floors)'),
      label: t('Apartment in a high-rise building (5 or more floors)'),
    },
    {
      value: t('Mobile home'),
      label: t('Mobile home'),
    },
  ];

  const onChange = (index: number, subIndex: number, value: string) => {
    if (index === 1) {
      setAddressParams({ ...addressParams, address: `${value}` });
    } else if (index === 2) {
      if (subIndex === 0) {
        setFamilyInfoParams({ ...familyInfoParams, adult_num: `${value}` });
      } else if (subIndex === 1) {
        setFamilyInfoParams({ ...familyInfoParams, children_num: `${value}` });
      } else if (subIndex === 2) {
        setFamilyInfoParams({ ...familyInfoParams, elderly_num: `${value}` });
      }
    } else if (index === 3) {
      setLevel(value);
    } else if (index === 4 && value !== inputPet) {
      setInputPet(value);
    }
  };

  const onProgressStep = (steps: boolean[]) => {
    return steps.map((item, index) => (
      <div key={index} className={classNames(styles.indexDiv, item ? styles.active : '')} />
    ));
  };
  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChangeIndex = (index: number) => {
    if (index === 1) {
      if (currentStep === 1) {
        if (addressParams?.address === undefined) {
          message.error(t('Please enter your city'));
          return;
        }
        handleChange('');
        setAddressList([]);
      } else if (currentStep === 2) {
        if (
          (familyInfoParams?.adult_num === undefined ||
            familyInfoParams?.adult_num === null ||
            familyInfoParams?.adult_num === '0') &&
          (familyInfoParams?.children_num === undefined ||
            familyInfoParams?.children_num === null ||
            familyInfoParams?.children_num === '0') &&
          (familyInfoParams?.elderly_num === undefined ||
            familyInfoParams?.elderly_num === null ||
            familyInfoParams?.elderly_num === '0')
        ) {
          message.error(t('Please enter at least one family member'));
          return;
        }
      } else if (currentStep === 3) {
        if (level === undefined) {
          message.error(t('Please select your housing type'));
          return;
        } else if (!isSingle) {
          if (getLoginState()) {
            addInfo();
          } else {
            onGetStartedClick('none');
            onOpenLoginModal(true);
          }
        }
      } else if (currentStep === 4 && isSingle) {
        if (havePet && !inputPet) {
          message.error(
            t('Please enter your pet information to customize a safety plan for them as well'),
          );
          return;
        } else {
          if (getLoginState()) {
            addInfo();
          } else {
            onGetStartedClick('none');
            onOpenLoginModal(true);
          }
        }
      }
    }
    if (index + currentStep < 1) {
      setCurrentStep(1);
      return;
    } else if (index + currentStep > 3 && !isSingle) {
      setCurrentStep(3);
      return;
    } else if (index + currentStep > 4 && isSingle) {
      setCurrentStep(4);
      return;
    }

    setCurrentStep((tempIndex) => tempIndex + index);
  };

  const onAddressSearch = async (address: string) => {
    const GOOGLE_GEOCODING_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json';

    if (!address || typeof address !== 'string') {
      return;
    }

    try {
      const response = await axios.get(GOOGLE_GEOCODING_API_URL, {
        params: {
          address,
          key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
          language: router?.locale,
        },
      });

      const { results, status } = response.data;

      if (status === 'OK' && results.length > 0) {
        const tempFirstItem = results?.length > 0 ? results[0] : {};
        const address_components = tempFirstItem?.address_components ?? [];

        if (address_components?.length > 0) {
          let tempIndex = 0;
          let tempAddress = '';
          address_components.reverse().forEach((item: any) => {
            if (tempIndex < 3 && item?.types?.includes('political')) {
              tempAddress =
                tempAddress?.length > 0 ? `${tempAddress} ${item?.long_name}` : item?.long_name;
              tempIndex += 1;
            }
          });

          setAddressList([
            {
              key: tempAddress,
              value: tempAddress,
              label: tempAddress,
            },
          ]);
        }
      } else {
        setAddressList([]);
        // res.status(404).json({ error: 'No results found' });
      }
    } catch (error: any) {
      message.error(error);
    }
  };

  const getContent = useCallback(() => {
    return (
      <div className={styles.content}>
        <div className={styles.topTitle}>
          {t('Customized Emergency Preparedness Guide').toLocaleUpperCase()}
        </div>
        <div className={styles.topSubTitle}>{getTitleData().title}</div>
        <div className={styles.topDesc}>{getTitleData().desc}</div>

        <div className={styles.infoContent}>
          <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
            <Input
              ref={inputRef}
              className={classNames(
                styles.inputPlaceholder,
                addressParams?.address?.length > 0 && !isFocused ? styles.active : '',
              )}
              style={{ marginBottom: '5px' }}
              placeholder={
                addressParams?.address?.length > 0
                  ? addressParams?.address
                  : t('Please enter your city')
              }
              value={inputValue}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            />
            <div
              className={styles.bottomSelectContent}
              style={{ display: addressList?.length > 0 ? 'block' : 'none' }}
            >
              {addressList?.map((item, index) => {
                return (
                  <span
                    key={item?.label}
                    className={styles.searchBtn}
                    onClick={(e) => {
                      e?.stopPropagation();
                      onChange(1, 0, item?.label);
                      handleChange('');
                      // inputRef.current?.blur()
                      setAddressList([]);
                    }}
                  >
                    {item?.label}
                  </span>
                );
              })}
            </div>
          </div>

          <div style={{ display: currentStep === 2 ? 'block' : 'none' }}>
            {[
              t('The number of adults'),
              t('The number of children'),
              t('The number of elderly'),
            ].map((item, index) => {
              let tempVal;
              if (index === 0) {
                tempVal = addressParams?.adult_num;
              } else if (index === 1) {
                tempVal = addressParams?.children_num;
              } else {
                tempVal = addressParams?.elderly_num;
              }

              return (
                <Select
                  key={item}
                  defaultValue={tempVal}
                  value={tempVal}
                  showSearch
                  className={classNames(styles.selectContent, styles.preIcon)}
                  placeholder={item}
                  optionFilterProp="children"
                  onChange={(value: string) => {
                    onChange(2, index, value);
                  }}
                  filterOption={filterOption}
                  options={yourFamilyOptions}
                />
              );
            })}
          </div>

          <div style={{ display: currentStep === 3 ? 'block' : 'none' }}>
            <Select
              showSearch
              className={styles.selectContent}
              style={{ minWidth: '100%', maxWidth: '100%' }}
              value={level}
              placeholder={t('Please select your housing type')}
              optionFilterProp="children"
              onChange={(value: string) => {
                onChange(3, 0, value);
              }}
              filterOption={filterOption}
              options={yourHousingTypeOptions}
            />
          </div>

          <div
            style={{
              display: currentStep === 4 && isSingle ? 'block' : 'none',
            }}
          >
            <div className={styles.radioContent}>
              <div className={styles.radioContainer}>
                <Radio.Group
                  onChange={(e) => {
                    setHavePet(e.target.value);
                  }}
                  value={havePet}
                  options={[
                    { value: 1, label: t('Yes') },
                    { value: 0, label: t('No') },
                  ]}
                />
              </div>

              {havePet === 1 && (
                <TextArea
                  placeholder={t('What pets do you have? (e.g., I have a dog)')}
                  className={styles.textArea}
                  value={inputPet}
                  onChange={(e) => onChange(4, havePet, e.target.value)}
                />
              )}
            </div>
          </div>
        </div>

        <div className={styles.bottomContainer}>
          <div className={styles.progressContent}>
            <span
              className={styles.desc}
              dangerouslySetInnerHTML={{
                __html: !isSingle
                  ? currentStep === 3 && level !== undefined
                    ? t('Analysis complete.<br>Your guide is ready.')
                    : `${t('Organizing')}...`
                  : currentStep === 4 && (!havePet || (havePet && inputPet))
                    ? t('Analysis complete.<br>Your guide is ready.')
                    : `${t('Organizing')}...`,
              }}
            />

            <div className={styles.progressStep}>
              {onProgressStep(getProgressState(isSingle ? [1, 2, 3, 4] : [1, 2, 3]))}
            </div>
          </div>

          <div className={styles.nextContainer}>
            <span
              className={classNames(styles.preIcon, currentStep === 1 ? styles.disable : '')}
              onClick={() => onChangeIndex(-1)}
            >
              <LeftOutlined />
            </span>

            <span
              className={classNames(
                styles.nextIcon,
                currentStep === (isSingle ? 4 : 3) ? styles.nextView : '',
                currentStep === 4 && havePet && !inputPet ? styles.disableBtn : '',
              )}
              onClick={() => onChangeIndex(1)}
            >
              {currentStep !== (isSingle ? 4 : 3) ? <RightOutlined /> : t('View')}
            </span>
          </div>
        </div>
      </div>
    );
  }, [
    currentStep,
    addressParams,
    familyInfoParams,
    level,
    addressList,
    addressParams?.adult_num,
    addressParams?.children_num,
    addressParams?.elderly_num,
    inputValue,
    isFocused,
    havePet,
    inputPet,
  ]);

  useEffect(() => {
    if (isOpenCreateReportAlert) {
      setCurrentStep(1);
      setAddressList([]);
      setAddressParams({});
      setFamilyInfoParams({});
      setLevel(undefined);
    }
  }, [isOpenCreateReportAlert]);

  return (
    <>
      {!payId && getContent()}

      {payId && (
        <PayAlertContent
          report_type={currentReportType}
          onConfirm={(index) => {
            orderSubscribeRequest(payId, index);
            setPayId(null);
            onOpenCreateReportAlert(false);
          }}
        />
      )}
      {/* <CommonAlert
        open={payId != null}
        title={t('Unlock Your Guide')}
        desc={t(
          "Get your customized Emergency Preparedness Guide Now. It’s more than a guide—it's an investment in your safety and that of your loved ones.",
        )}
        btnTitle={t('Unlock Now!')}
        onCloseAction={() => setPayId(null)}
        onConfirmClick={() => {
          orderSubscribeRequest(payId);
          setPayId(null);
          onOpenCreateReportAlert(false);
        }}
      /> */}
    </>
  );
};

const CreateReportAlertContent = () => {
  let { isOpenCreateReportAlert } = useUserProvider();
  const { onOpenCreateReportAlert } = useUserProvider();

  const isProd =
    process.env.NODE_ENV === 'production' && !process.env.NEXT_APP_API_HOST.includes('-test.');

  const stripePromise = loadStripe(
    isProd
      ? 'pk_live_51PLeg1HIujCwi3nIRess292nAvl6U59Y8GPSMQ5Rdj0Zs0ElkTFAFub7DABu2H8QkpOXvhkUERrMnZDnBZ9lKRJ300jgYVMFOM'
      : 'pk_test_51PLeg1HIujCwi3nI09m7RSeoETi4JbzbhPum2SP70mitBmKmHexUx4OgDspMgfBr35Q6uIroqPsJeboXI9H382IA00YozmzqKa',
  );

  return (
    <>
      <Modal
        open={isOpenCreateReportAlert}
        closeIcon={<CloseOutlined style={{ color: 'var(--app-text-color)', fontSize: '20px' }} />}
        onCancel={() => onOpenCreateReportAlert(false)}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={null}
        maskClosable={false}
        style={{ padding: 0 }}
        destroyOnClose={true}
      >
        <Elements stripe={stripePromise}>
          <DetailContent />
        </Elements>
      </Modal>
    </>
  );
};

export default CreateReportAlertContent;
