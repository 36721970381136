import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import ResizeObserver from 'rc-resize-observer';
import type { SizeInfo } from 'rc-resize-observer';
// import useBusinessSettingsDisplay from '@/lib/hooks/common/useBusinessSettingsDisplay';
import { useRouter } from 'next/router';
import i18nextConfig from '../../next-i18next.config';
import useScreenSize from '@/lib/hooks/utils/useScreenSize';

type ProviderType = {
  isMobile?: boolean;
  isShopPage?: boolean;
  menus?: Array<any>;
  screenSize?: SizeInfo;
  userLocale?: string;
  handleLocale?: (loc: string) => void;
  isIPhone?: boolean;
  onShowArticleBottomAlert?: (show?: boolean) => void;
  isShowArticleBottomAlert?: boolean;
};

const Context = createContext<ProviderType>({});

const Provider = ({ children }: any) => {
  const { width } = useScreenSize();

  const [screenSize, setScreenSize] = useState<SizeInfo>();
  const [isMobile, setIsMobile] = useState<boolean>(width < 786);
  const [locale, setLocale] = useState<string>();
  const router = useRouter();
  // const { value: menuRes } = useBusinessSettingsDisplay('CATEGORY_NAV_DROPDOWN_ITEMS');

  const [isIPhone, setIsIPhone] = useState<boolean>(undefined);
  const [isShowArticleBottomAlert, setShowArticleBottomAlert] = useState<boolean>(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsIPhone(/iPhone/i.test(userAgent));
  }, []);

  useEffect(() => {
    setIsMobile(width < 786);
    const handleResize = () => {
      setIsMobile(width < 786);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  const handleIsMobile = (size: SizeInfo) => {
    setScreenSize(size);
    setIsMobile(size.width < 786);
  };

  const handleLocale = (loc: string | undefined) => {
    setLocale(loc ?? i18nextConfig.i18n.defaultLocale);
  };

  // const formattedCategoryNavDropdownItems = useCallback(() => {
  //   return (menuRes?.value || []).map((term: any) => {
  //     return {
  //       text: term.text,
  //       path: createShopAllQueryString(term.categories.join(','), term.storeIds),
  //     };
  //   });
  // }, [menuRes?.value]);

  const onShowArticleBottomAlert = (show?: boolean) => {
    setShowArticleBottomAlert(() => show);
  };

  const exposed = {
    // menus: [],
    isShopPage: router.pathname.startsWith('/shop'),
    isMobile: isMobile,
    screenSize,
    userLocale: locale,
    handleLocale,
    isIPhone,
    onShowArticleBottomAlert,
    isShowArticleBottomAlert,
  };

  return (
    <Context.Provider value={exposed}>
      <ResizeObserver key="resize-observer" onResize={handleIsMobile}>
        {children}
      </ResizeObserver>
    </Context.Provider>
  );
};

export const useConfigProvider = () => useContext(Context);

export default Provider;
